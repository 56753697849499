<!-- ======= Header ======= -->
<header id="header" class="fixed-top">
    <div class="container d-flex align-items-center justify-content-between">
        <h1 class="logo"><a href="">
                <img src="../../assets/img/apple-touch-icon.png" /></a></h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
        <!-- <nav id="navbar" class="navbar">
            <ul>
                <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
                <li><a class="nav-link scrollto" href="#about">About Us</a></li>
                <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
        </nav> -->
        <!-- .navbar -->
    </div>
</header>
<!-- End Header -->
<main id="main">
   
    <!-- ======= Contact Section ======= -->
    <section id="accoutDeletion" class="accoutDeletion contact">
        <div class="container" data-aos="fade-up">
            <div class="section-title">
                <h2>Account Deletion Form</h2>
            </div>
            <div class="row mt-10 justify-content-center">
                <div class="col-lg-8 mt-5 mt-lg-0">
                    <form [formGroup]="accountDeletionForm" class="php-email-form" (ngSubmit)="contactUsSubmit()">
                        <div class="form-group mt-3">
                            <input type="text" class="form-control" name="email" id="email" placeholder="Enter your Email"
                                required formControlName="email">
                            <div *ngIf="submitted && accountDeletionForm.controls.email.errors" class="form-error">
                                <p *ngIf="accountDeletionForm.controls.email.errors?.required">
                                    Email is required</p>
                            </div>
                        </div>
                        <div class="my-3">
                            <div class="loading">Loading</div>
                            <div class="error-message"></div>
                            <div class="sent-message">Your delete account request has been sent successfully. Thank you!</div>
                        </div>
                        <div class="text-center">
                            <button type="submit">Delete My Account</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- End Contact Section -->
</main>
<!-- End #main -->
<!-- ======= Footer ======= -->
<footer id="footer">
    <div class="container d-md-flex py-4">
        <div class="me-md-auto text-center text-md-start">
            <div class="copyright"> &copy; Copyright <strong><span>NewsPod Pvt. Ltd.</span></strong> All Rights
                Reserved </div>
        </div>
    </div>
</footer>
<!-- End Footer -->
<!-- <div id="preloader"></div> -->
<a href="javascript:;" class="back-to-top d-flex align-items-center justify-content-center">
    <i class="bi bi-arrow-up-short"></i>
</a>