import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account-deletion-request',
  templateUrl: './account-deletion-request.component.html',
  styleUrls: ['./account-deletion-request.component.css']
})
export class accountDeletionRequestComponent implements OnInit {

  accountDeletionForm: FormGroup
  submitted: Boolean = false;

  constructor(
    public _formBuilder: FormBuilder,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.buildForm()
  }

  buildForm() {
    this.accountDeletionForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[A-Za-z\d._-]{2,64}\@([A-Za-z\d]+)\.[A-Za-z\d]+(.[A-Za-z\d]+)?$/)]],
    })
  }

  contactUsSubmit() {
    this.submitted = true;
    if(this.accountDeletionForm.valid) {
      this.toastr.success('Your delete account request has been sent successfully. Thank you!');
      this.accountDeletionForm.reset()
      this.submitted = false;
    }
  }

}
